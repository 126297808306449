import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header'; 
import { Link } from 'react-router-dom';

function TermsPage() {
  return (
    <div className='page-wrapper'><Header /><main className="policy-page">
          <Helmet>
              <title>Echovane - Terms & Conditions</title>
              <meta name="description" content="Review the terms and conditions for using Echovane’s AI-driven market research platform." />
          </Helmet>
          <h1>Terms & Conditions</h1>
          <p><strong>Last Updated:</strong> 18 September 2024 </p>

          <h2>1. Introduction</h2>
          <p>Welcome to Echovane Inc ("Company", "we", "our", "us"). By using our website and services (collectively, the "Service"), you agree to these Terms of Service ("Terms"). These Terms govern your access to and use of our AI-powered market research platform.</p>
          <p>If you do not agree with any part of these Terms, you may not use the Service. For any questions or concerns, please contact us at contact@echovane.com.</p>
          <p>Your use of the Service is also governed by our <Link to="/privacy">Privacy Policy</Link>, which explains how we collect, use, and protect your information.</p>

          <h2>2. Eligibility</h2>
          <p>You must be at least 18 years old or the legal age in your jurisdiction to form a binding contract to use our Service. If you are under 18, you may use the Service only under the supervision of a parent or guardian.</p>

          <h2>3. Account Registration</h2>
          <p>To access certain features, you may be required to create an account. You agree to provide accurate and up-to-date information during registration and to keep it updated. You are responsible for maintaining the security of your account credentials and for all activities under your account. Notify us immediately of any unauthorized use of your account.</p>

          <h2>4. Use of the Service</h2>
          <p>Echovane grants you a limited, non-exclusive, non-transferable, and revocable license to use the Service in compliance with these Terms. You may not:</p>
          <ul>
              <li>Use the Service for unlawful purposes.</li>
              <li>Disrupt, damage, or impair the Service.</li>
              <li>Interfere with other users’ access or enjoyment of the Service.</li>
          </ul>

          <h2>5. Prohibited Conduct</h2>
          <p>You agree not to:</p>
          <ul>
              <li>Violate any applicable laws or regulations.</li>
              <li>Exploit or harm minors.</li>
              <li>Use the Service to send spam or engage in fraudulent activities.</li>
              <li>Attempt unauthorized access to the Service or its data.</li>
              <li>Introduce harmful code, viruses, or malware.</li>
          </ul>
          <p>Echovane reserves the right to employ fraud detection measures and take action against users engaging in prohibited activities, including account suspension or termination.</p>

          <h2>6. Subscriptions and Purchases</h2>
          <p>Some features of the Service may require a subscription or payment. By purchasing a subscription:</p>
          <ul>
              <li>You authorize Echovane to charge your payment method for the applicable fees.</li>
              <li>Subscriptions automatically renew unless canceled prior to the end of the billing cycle.</li>
              
          </ul>

          <h2>7. Refunds and Cancellation Policy</h2>
          <p>Refunds are subject to our Refund Policy, which can be found <Link to="/cancellation">here</Link>.</p>

          <h2>8. Intellectual Property</h2>
          <p>All intellectual property rights in the Service, including its content, features, and functionality, are owned by Echovane or its licensors. You may not:</p>
          <ul>
              <li>Copy, reproduce, distribute, or create derivative works based on the Service without written permission.</li>
              <li>Use any Echovane trademarks without authorization.</li>
          </ul>

          <h2>9. AI-Generated Content and Insights</h2>
          <p>Echovane uses AI to generate insights and analyze data. While we strive for accuracy, Echovane does not guarantee the completeness or representativeness of AI-generated content or insights. Users are responsible for reviewing and validating AI-generated outputs before use. Echovane shall not be liable for decisions made based on these insights.</p>

          <h2>10. Privacy Policy</h2>
          <p>Your use of the Service is governed by our <Link to="/privacy">Privacy Policy</Link>, which outlines how we collect, store, and process your data. By using the Service, you consent to the practices described in our Privacy Policy.</p>

          <h2>11. Service Availability and Performance</h2>
          <p>While Echovane aims to provide uninterrupted access to the Service, we do not guarantee continuous availability or performance. We reserve the right to modify or suspend the Service at any time without notice.</p>

          <h2>12. Representativeness of Results</h2>
          <p>Echovane provides tools for analyzing market research data but does not guarantee the accuracy or representativeness of the results. Users are solely responsible for interpreting and applying these results.</p>

          <h2>13. Changes to the Terms</h2>
          <p>Echovane reserves the right to modify these Terms at any time. Updates will be posted on our website, and continued use of the Service constitutes acceptance of the updated Terms.</p>

          <h2>14. Disclaimer of Warranties</h2>
          <p>The Service is provided "as is" without any warranties, express or implied. Echovane does not guarantee that the Service will meet your requirements, be error-free, or operate without interruptions.</p>

          <h2>15. Limitation of Liability</h2>
          <p>Echovane is not liable for any indirect, incidental, special, or consequential damages resulting from your use of the Service.</p>

          <h2>16. Termination</h2>
          <p>We may terminate or suspend your account at any time for violations of these Terms. Upon termination, your right to use the Service will cease immediately.</p>

          <h2>17. Governing Law</h2>
          <p>
            These Terms are governed by and construed in accordance with the laws of India. Any disputes arising under these Terms shall be subject to the exclusive jurisdiction of the courts of Thane, Maharashtra, India.
            </p>

          <h2>18. Data Ownership and Compliance</h2>
          <p>Users retain ownership of the data they upload or generate through the Service. Users are responsible for obtaining necessary consents from respondents and ensuring compliance with data protection laws such as GDPR and other regulations.</p>

          <h2>19. Force Majeure</h2>
          <p>Echovane shall not be held liable or responsible for any failure or delay in the performance of its obligations under these Terms and Conditions due to events beyond its reasonable control. Such events include, but are not limited to, acts of God, natural disasters, pandemics, strikes, labor disputes, wars, acts of terrorism, governmental actions, interruptions in communication or utility services, internet outages, or other unforeseen circumstances. In the event of a force majeure situation, Echovane will make reasonable efforts to resume the affected services as soon as practicable. However, no refunds or compensation will be provided for any disruption caused by such events unless otherwise stated in a separate agreement.</p>

          <h2>20. Contact Information</h2>
          <p>If you have any questions or concerns about these Terms, please contact us at:</p>
          <ul>
              <li><strong>Email:</strong> contact@echovane.com</li>
              <li><strong>Business Address:</strong> 8 The Green, Ste R, Dover, Delaware 19901 </li>
          </ul>
      </main></div>
  );
}

export default TermsPage;
