import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header'; 

function PrivacyPage() {
  return (
    <div className='page-wrapper'><Header /><main className="policy-page">
          <Helmet>
              <title>Echovane - Privacy Policy</title>
              <meta name="description" content="Learn how Echovane handles data privacy, security, and compliance for its AI-driven market research services." />
          </Helmet>
          <h1>Privacy Policy</h1>
          <p><strong>Last Updated:</strong> 18 September 2024</p>

          <h2>1. Information We Collect</h2>
          <h3>1.1 Personal Information</h3>
          <p>We may collect the following personal information when you interact with our platform:</p>
          <ul>
              <li><strong>Contact Information:</strong> Name, email address, phone number.</li>
              <li><strong>Account Information:</strong> Username, password, and other login credentials.</li>
              <li><strong>Billing Information:</strong> Credit card details, billing address, and other payment-related information.</li>
              <li><strong>Communication Data:</strong> Records of communications you have with us, including customer support inquiries.</li>
          </ul>

          <h3>1.2 Non-Personal Information</h3>
          <p>We may collect non-personal information, which cannot be used to identify you, such as:</p>
          <ul>
              <li><strong>Usage Data:</strong> Information about how you use our services, including access times, browser type, and device information.</li>
              <li><strong>Cookies and Tracking Technologies:</strong> Data collected through cookies, web beacons, and other tracking technologies</li>
          </ul>

          <h2>2. How We Use Your Information</h2>
          <h3>2.1 To Provide and Manage Our Services</h3>
          <ul>
              <li>Create and manage your account.</li>
              <li>Facilitate and process transactions.</li>
              <li>Provide customer support and respond to inquiries.</li>
          </ul>

          <h3>2.2 To Improve Our Services</h3>
          <ul>
              <li>Analyze usage patterns to enhance our platform.</li>
              <li>Develop new features and improve existing functionalities.</li>
              <li>Conduct research and analysis to better understand user needs.</li>
          </ul>

          <h3>2.3 To Communicate With You</h3>
          <ul>
              <li>Send administrative information such as updates, security alerts, and support messages.</li>
              <li>Send marketing communications, where permitted by law, to inform you of our services, promotions, and events.</li>
          </ul>

          <h3>2.4 To Ensure Security and Compliance</h3>
          <ul>
              <li>Monitor and analyze activities to prevent fraud and ensure the security of our platform.</li>
              <li>Comply with legal obligations, resolve disputes, and enforce our terms and conditions.</li>
          </ul>

          <h2>3. Data Sharing and Disclosure</h2>
          <h3>3.1 With Service Providers</h3>
          <p>We may share your information with trusted third-party service providers, including:</p>
          <ul>
              <li><strong>Amazon Web Services (AWS):</strong> For secure data storage and infrastructure management.</li>
              <li><strong>Supabase:</strong> For database services and secure data management.</li>
          </ul>
          <p>These providers are bound by confidentiality agreements and are only permitted to use your data to provide services to us.</p>

          <h3>3.2 For Legal and Compliance Purposes</h3>
          <p>We may disclose your information if required by law, or if we believe that such action is necessary to:</p>
          <ul>
              <li>Comply with legal obligations or government requests.</li>
              <li>Protect the rights, property, and safety of Echovane, our users, or others.</li>
              <li>Investigate and prevent fraudulent activities or security breaches.</li>
          </ul>

          <h3>3.3 Business Transfers</h3>
          <p>In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred to the acquiring company.</p>

          <h2>4. Data Storage and Security</h2>
          <h3>4.1 Secure Storage</h3>
          <p>Your data is stored securely on AWS and Supabase servers, both of which comply with international security standards. These platforms provide robust security features, including:</p>
          <ul>
              <li><strong>Encryption:</strong> Data is encrypted at rest and in transit to protect against unauthorized access.</li>
              <li><strong>Access Controls:</strong> We implement role-based access controls to ensure that only authorized personnel can access sensitive information.</li>
          </ul>

          <h3>4.2 Data Retention</h3>
          <p>We retain your personal information for as long as necessary to provide our services, comply with legal obligations, and resolve disputes. When data is no longer needed, we will securely delete or anonymize it.</p>

          <h3>4.3 International Data Transfers</h3>
          <p>If you are located outside the United States, please be aware that your information may be transferred to and processed in the United States or other countries where our service providers are located. We ensure that adequate safeguards are in place to protect your data in accordance with this Privacy Policy.</p>

          <h2>5. Your Rights</h2>
          <h3>5.1 Access and Correction</h3>
          <p>You can access and update your personal information through your account settings. If you need assistance, please contact us at contact@echovane.com</p>

          <h3>5.2 Data Portability</h3>
          <p>You have the right to request a copy of your personal data in a structured, commonly used, and machine-readable format.</p>

          <h3>5.3 Deletion and Erasure</h3>
          <p>You may request that we delete your personal information. We will comply with your request, subject to certain legal obligations that may require us to retain your data.</p>

          <h3>5.4 Opt-Out</h3>
          <p>You can opt out of receiving marketing communications from us by following the unsubscribe instructions in our emails or by contacting us directly.</p>

          <h2>6. Cookies and Tracking Technologies</h2>
          <p>We use cookies and similar tracking technologies to enhance your experience on our platform. These technologies allow us to:</p>
          <ul>
              <li>Remember your preferences and settings.</li>
              <li>Understand how you interact with our services.</li>
              <li>Provide personalized content and advertising.</li>
          </ul>

          <h2>7. Third-Party Links</h2>
          <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices of these external sites. We encourage you to review the privacy policies of any third-party websites you visit.</p>

          <h2>8. Children’s Privacy</h2>
          <p>Our services are not intended for individuals under the age of 16. We do not knowingly collect personal information from children under 16. If we become aware that we have collected such information, we will take steps to delete it promptly. If you believe that we have collected personal information from a child under 16, please contact us at contact@echovane.com</p>

          <h2>9. Changes to This Privacy Policy</h2>
          <p>We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. We will notify you of any significant changes by posting the new policy on our website and updating the "Last Updated" date. We encourage you to review this Privacy Policy periodically.</p>

          <h2>10. Contact Us</h2>
          <p>If you have any questions or concerns about this Privacy Policy or how we handle your personal information, please contact us at:</p>
          <ul>
              <li><strong>Email:</strong> contact@echovane.com</li>
              <li><strong>Business Address:</strong> 8 The Green, Ste R, Dover, Delaware 19901 </li>
          </ul>
      </main></div>
  );
}

export default PrivacyPage;
