import React from 'react';
import { Link } from 'react-router-dom';
import { FaTwitter, FaYoutube, FaLinkedin } from 'react-icons/fa';
import '../App.css'; // Assumes global styles; you can also create a separate Footer.css file if needed.

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-container">
      <div className="footer-columns">
        {/* Terms Section */}
        <div className="footer-column">
          <ul className="footer-list">
            <li><Link to="/privacy" className="footer-link">Privacy Policy</Link></li>
            <li><Link to="/terms" className="footer-link">Terms & Conditions</Link></li>
            <li><Link to="/cancellation" className="footer-link">Cancellation & Refund Policy</Link></li>
            <li><Link to="/support" className="footer-link">Support</Link></li>
          </ul>
        </div>

        {/* Social Section with Icons */}
        <div className="footer-column">
          <ul className="footer-list social-icons">
            <li>
              <a href="https://x.com/EchovaneAI" target="_blank" rel="noopener noreferrer" className="footer-icon">
                <FaTwitter size={24} />
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/@Echovane" target="_blank" rel="noopener noreferrer" className="footer-icon">
                <FaYoutube size={24} />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/echovane" target="_blank" rel="noopener noreferrer" className="footer-icon">
                <FaLinkedin size={24} />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-top">
        <p className="footer-copyright">
          &copy; {currentYear} Echovane Inc. All rights reserved.
        </p>
        <p className="footer-contact">contact@echovane.com</p>
      </div>
    </footer>
  );
}

export default Footer;
