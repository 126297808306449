import React, { useEffect, useRef } from 'react';

const AnimatedElement = ({ className, children }) => {
  const elementRef = useRef(null);

  useEffect(() => {
    const element = elementRef.current;

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio < 0.8) {
          element.classList.add('paused');
          element.classList.remove('running');
        } else {
          element.classList.add('running');
          element.classList.remove('paused');
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, { threshold: 0.8 });

    if (element) {
      observer.observe(element);
    }
    //s

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  return (
    <div className={className} ref={elementRef}>
      {children}
    </div>
  );
};

export default AnimatedElement;
