import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header'; 

function SupportPage() {
  return (
    <div className='page-wrapper'><Header /><main className="policy-page">
          <Helmet>
              <title>Echovane - Customer Support</title>
              <meta name="description" content="Reach out to Echovane’s support team for assistance with your AI market research setup, account, or survey configurations." />
          </Helmet>
          <h1>Customer Support</h1>

          <h2>Contacting Our Support Team</h2>
          <p>If you need assistance, our support team is here to help. You can reach us through the following channels:</p>
          <ul>
              <li><strong>Email:</strong> contact@echovane.com</li>
              <li><strong>Phone:</strong> +1 415 226 6778</li>
          </ul>

          <h2>Response Times & Service Level Agreement (SLA)</h2>
          <p>Our support team operates during the following hours:</p>
          <ul>
              <li><strong>Business Hours:</strong> Monday to Friday, 9:00 AM - 5:00 PM IST.</li>
              <li><strong>Response Times:</strong>
                  <ul>
                      <li>Standard Inquiries: Within 24 hours.</li>
                      <li>Urgent Issues (e.g., payment failures or account access): Within 4 hours during business hours.</li>
                  </ul>
              </li>
          </ul>

          <h2>Technical Assistance & Onboarding</h2>
          <p>We provide the following resources to ensure your success with Echovane:</p>
          <ul>
              <li><strong>User Guides:</strong> Step-by-step instructions for setting up and using our platform.</li>
              <li><strong>Video Tutorials:</strong> Comprehensive video walkthroughs for common tasks and features.</li>
              <li><strong>One-on-One Onboarding:</strong> Schedule a personalized onboarding session with our team to get started quickly.</li>
          </ul>
          <p>If you need further assistance, feel free to contact us. We are here to help!</p>
          <ul>
              <li><strong>Email:</strong> contact@echovane.com</li>
              <li><strong>Phone:</strong> +1 415 226 6778</li>
              <li><strong>Business Address:</strong> 8 The Green, Ste R, Dover, Delaware 19901 </li>
          </ul>
      </main></div>
  );
}

export default SupportPage;
